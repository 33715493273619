@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/dataroom/ui/components/Landing/variables.scss";
@import "@/dataroom/ui/components/Landing/investorSet/variables.scss";

@mixin intro-top-position($offset) {
  top: calc(100vw * 0.1228 / 4 - #{$offset}px);
}

.homeWrp {
  .intro {
    position: relative;
    margin-bottom: 160px;

    &:before {
      height: 119%;
      top: -185px;
      background: $tenantInvestorsetColor;

      @include mqMaxWidth(1260px) {
        top: -110px;
      }

      @include mqMaxWidth(1024px) {
        height: 85%;
      }

      @include mqMaxWidth(640px) {
        top: -40px;
        height: 125%;
      }
    }

    @include mqMaxWidth(1024px) {
      margin-bottom: 0;
    }
  }

  .introAnimationContainer {
    box-shadow: none;
    overflow: unset;
    background: none;
  }

  .title,
  .description {
    color: $titleColor;

    span {
      color: $titleColor;
    }
  }
}

.introPattern {
  bottom: -30px;
}

.description {
  width: 85%;

  @include mqMaxWidth(1024px) {
    width: 100%;
  }
}

.introAnimationShadow {
  border-radius: 5px;
  box-shadow: 0 10px 55px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: -1;
  top: 0;
  right: 28px;
  bottom: 38px;
  left: 0;
}

.introContent {
  position: relative;
  @include intro-top-position(90);

  @include mqMaxWidth(1260px) {
    @include intro-top-position(35);
  }

  @include mqMaxWidth(1024px) {
    top: 0;
  }
}

.homeWrp .supportWrp {
  position: relative;
  padding: 75px 0 200px;
  margin-bottom: 100px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 125%;
    top: -183px;
    transform: skewY(-7deg);
    background: $tenantInvestorsetColor;
  }

  @include mqMaxWidth(768px) {
    padding-top: 45px;
    padding-bottom: 160px;
    margin-bottom: 50px;
  }

  @include mqMaxWidth(720px) {
    padding-top: 25px;
    padding-bottom: 170px;
    margin-bottom: 0;
  }
}

.homeWrp .supportPattern {
  z-index: -1;
  margin-top: -5px;
}

.homeWrp .supportHeading,
.homeWrp .supportTitle {
  color: $titleColor;
}

.homeWrp .supportDescription {
  color: $secondaryColor;
}

.homeWrp .supportHeading {
  span {
    color: $tenantInvestorsetSecondaryColor;
  }
}

.homeWrp .supportStamp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: 0 auto;
  width: 110px;

  @include mqMaxWidth(768px) {
    bottom: 5px;
  }

  @include mqMaxWidth(720px) {
    bottom: 28px;
  }
}

.button {
  padding: 0 20px;
}
