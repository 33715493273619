@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.featuresWrp {
  max-width: 1236px;
  margin: 0 auto;
}

.features {
  margin-top: 100px;
}

.featuresRow {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.featuresColumnWrp {
  display: table-cell;
  padding: 70px 95px 55px;
  width: 50%;

  @include mqMaxWidth(1260px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mqMaxWidth(768px) {
    width: 100%;
    display: block;
    padding: 30px 30px 15px;
  }
}

.featuresColumn {
  display: table;
  margin: 0 auto;
}

.columnWithShadow {
  background: #f9f9f9;
  border-left: 1px solid #f3f3f3;
}

.featureColumnHeader {
  text-align: center;
  margin-bottom: 42px;
}

.featureColumnIcon {
  display: block;
  margin: 0 auto 23px;
}

.featureColumnTitle {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #2d2d2d;
}

.featuresCta {
  margin-top: 25px;
  text-align: center;

  @include mqMaxWidth(768px) {
    margin-top: 10px;
  }
}

.featuresCtaBtn {
  max-width: 307px;
  width: 100%;
  margin-right: 0;
}

.feature {
  display: table;
  margin-bottom: 45px;

  @include mqMaxWidth(1024px) {
    margin-bottom: 25px;
  }

  @include mqMaxWidth(768px) {
    margin-bottom: 38px;
  }

  @include mqMaxWidth(640px) {
    display: block;
    margin-bottom: 25px;
  }
}

.featureIcon {
  display: table-cell;
  width: 16px;
  height: 16px;
  vertical-align: middle;

  i {
    fill: #4ab675;
    vertical-align: middle;
  }
}

.featureTitle {
  display: table-cell;
  padding-left: 20px;
  font-size: 18px;
  line-height: 21px;
  color: #616161;

  @include mqMaxWidth(1024px) {
    font-size: 14px;
    line-height: 18px;
  }

  @include mqMaxWidth(768px) {
    font-size: 18px;
    line-height: 21px;
  }
}
