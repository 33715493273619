@import "../base.scss";
@import "@/dataroom/ui/components/Landing/investorSet/variables.scss";

.pageContainer {
  padding-top: 66px;
  display: block;
  width: 100%;
  background-color: $baseBackgroundColor;
  overflow: hidden;

  @include mqMaxWidth($screenM) {
    overflow: visible;
  }
}

.intro {
  position: relative;
  padding-top: 104px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 1000px;
    top: 720px;
    left: 0;
    transform: skewY(-7deg);
    background: $tenantInvestorsetColor;

    @include mqMaxWidth($screenM) {
      display: none;
    }
  }

  @include mqMaxWidth(1268px) {
    padding-top: 50px;
  }

  @include mqMaxWidth($screenM) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title,
.description {
  color: #2d2d2d;
}

.description {
  max-width: 663px;

  span {
    font-weight: bold;
    color: #2d2d2d;
  }

  @include mqMaxWidth($screenM) {
    margin: 0 auto 51px;
  }
}

.titlePattern {
  text-align: center;
  margin: 45px 0 -30px;

  @include mqMaxWidth($screenM) {
    display: none;
  }
}
