@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.learnMoreContainer {
  max-width: none;
  margin: 0 auto;
  padding: 0 0 64px;

  @include mqMaxWidth(1024px) {
    padding-bottom: 50px;
  }

  @include mqMaxWidth(720px) {
    padding: 0;
  }

  @include mqMaxWidth(640px) {
    padding-bottom: 0;
  }
}

.learnMoreDescription {
  padding: 0 20px;
  max-width: 654px;
  margin: 0 auto;
}

.learnMoreButtons {
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 46px;
}
